<template>
  <v-container class="">
    <v-row>
      <v-col
        v-for="condition in conditionList"
        :key="condition.id"
        sm="12"
        lg="3"
      >
        <v-card>
          <v-img class="justify-center" :src="condition.image"></v-img>
          <v-card-title class="condition-title v-card__title-dashboard">
            {{ condition.route }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-chip
              class="ma-2"
              color="#EF515B"
              outlined
              :to="`/${condition.id}/treatments`"
            >
              Show patient list
            </v-chip>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              color="orange lighten-2"
              text
              @click="showTreatment(condition.id)"
            >
              Treatments
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="showTreatment(condition.id)">
              <v-icon>{{
                show == condition.id ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show == condition.id">
              <v-divider></v-divider>
              <v-list-item
                v-for="treatment in condition.treatmentList"
                :key="treatment.id"
                :to="`/${condition.id}/treatments/${treatment.id}/patientList`"
              >
                <v-list-item-content>
                  <v-list-item-title class="lyv-uc"
                    >{{ treatment.route }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      top
      v-model="ui.infoSnackbar.state"
      :timeout="ui.infoSnackbar.timeout || 6000"
      :color="ui.infoSnackbar.color"
    >
      {{ ui.infoSnackbar.info }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { Main } from "../../services/modules";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    conditionList: [],
    treatmentList: [],
    show: null,
    ui: {
      retrieveLoading: false,
      infoSnackbar: {
        state: false,
        color: "error",
        info: null,
        timeout: 6000,
      },
    },
  }),
  methods: {
    condition() {
      this.$router.push({ name: "Patient" });
    },
    showTreatment(id) {
      if (this.show == id) {
        this.show = null;
      } else {
        this.show = id;
      }
    },
    getImgUrl(image) {
      var images = require.context("../../assets/", false, /\.png$/);
      return images("./" + image);
    },
    getAllConditon() {
      Main.getAllConditon()
        .then((response) => {
          let conditionList = response.data.data;
          for (let treatment in conditionList) {
            let treatmentList = [];
            let treatments = conditionList[treatment].treatmentIds;
            for (let j = 0; j < treatments.length; j++) {
              const e = treatments[j];
              treatmentList.push({
                id: e._id,
                route: e.name,
              });
            }
            this.conditionList.push({
              id: conditionList[treatment].id,
              route: conditionList[treatment].name,
              count: conditionList[treatment].treatmentIds.length,
              image: conditionList[treatment].image,
              treatmentList: treatmentList,
            });
          }
        })
        .catch(() => {});
    },
    ...mapMutations({
      setSnack: "snackbar/setSnack",
    }),
  },
  mounted() {
    this.getAllConditon();
    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    window.FreshworksWidget("identify", "ticketForm", {
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
    });
    window.FreshworksWidget("disable", "ticketForm", ["name", "email"]);
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
.condition-title {
  text-transform: capitalize;
}
.condition-title {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.action {
  padding-top: 0px !important;
}
.v-sheet.v-card {
  border-radius: 10px;
}
.lyv-uc {
  text-transform: capitalize;
}
.v-card__title-dashboard {
  color: #6da2d4 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}
.v-chip.v-size--default {
  font-size: 12px !important;
}
.v-btn.v-size--default,
.v-btn.v-size--large {
  font-size: 0.8rem !important;
}
</style>
